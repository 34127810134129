<template>
  <div class="OnboardingStep4 p-a-xl has-text-centered is-full-width is-column is-aligned-center">
    <h4 class="title is-4 has-text-weight-bold">
      {{ $t('pages.onboarding.step_4.content_title') }}
    </h4>

    <div class="is-size-5">
      <i18n path="pages.onboarding.step_4.confirming_email">
        <a
          slot="email"
          :href="`mailto:${userEmail}`"
          class="has-text-decoration-underline has-text-primary"
        >
          {{ userEmail }}
        </a>
      </i18n>
    </div>

    <div class="no_email is-flex is-aligned-middle is-aligned-center m-t-l">
      <span class="has-text-weight-semibold is-size-5">
        {{ $t('pages.onboarding.step_4.no_email') }}
      </span>

      <v-button
        :loading="isLoading"
        class="is-primary m-l-m m-b-none"
        @click="resendActivation"
      >
        {{ $t('pages.onboarding.step_4.resend') }}
      </v-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OnboardingStep4',

  props: {
    userEmail: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      isLoading: false
    }
  },

  methods: {
    async resendActivation () {
      try {
        await this.$confirm(this.$t('pages.onboarding.step_4.are_you_sure_about_new_activation'))

        this.isLoading = true

        await this.$api.post('activation/resend', { email: this.userEmail })

        this.$notify.success(this.$t('success.successfully_sent_new_email_with_token'))
      } catch (error) {
        if (error !== 'cancel') {
          this.$displayRequestError(error)
        }
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>
